<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        class="mr-4"
        @click="returnToProjects()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>OIDC Config</v-toolbar-title>
    </v-toolbar>
    <v-spacer/>
    <v-col class="mx-auto my-3" cols="12" lg="6" xl="8">

      <v-snackbar
        v-model="showSuccessAlert"
        :timeout="3000"
        color="success"
        top
      >
        Configuration saved successfully!
      </v-snackbar>

      <v-text-field
        v-model="oidcConfig.discovery_url"
        label="Discovery URL"
        :rules="[v => !!v || 'Discovery URL is required']"
        type="url"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.realm"
        label="Realm"
        :rules="[v => !!v || 'Realm is required']"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.client_id"
        label="Client ID"
        :rules="[v => !!v || 'Client ID is required']"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.client_secret"
        label="Client Secret"
        :rules="[v => !!v || 'Client Secret is required']"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.scope"
        label="Scope"
        :rules="[v => !!v || 'Scope is required']"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.redirect_url"
        label="Redirect URL"
        :rules="[v => !!v || 'Redirect URL is required']"
        required
      ></v-text-field>

      <v-text-field
        v-model="oidcConfig.logout_url"
        label="Logout URL"
        :rules="[v => !!v || 'Logout URL is required']"
        required
      ></v-text-field>

      <v-btn
        color="primary float-right"
        class="mr-4"
        @click="saveOIDCConfig()"
      >Save
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {
  name: 'OIDC',
  data() {
    return {
      oidcConfig: {
        discovery_url: '',
        client_id: '',
        client_secret: '',
        scope: '',
        redirect_url: '',
        logout_url: '',
        realm: '',
      },
      showSuccessAlert: false,
    };
  },
  created() {
    this.fetchOIDCConfig();
  },
  methods: {
    async returnToProjects() {
      EventBus.$emit('i-open-last-project');
    },
    async fetchOIDCConfig() {
      try {
        const response = await fetch('api/oidc/config');
        this.oidcConfig = await response.json();
        console.table(this.oidcConfig);
      } catch (error) {
        console.error(error);
      }
    },
    async saveOIDCConfig() {
      try {
        const response = await fetch('/api/oidc/config', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.oidcConfig),
        });
        if (response.ok) {
          // la configuration a été enregistrée avec succès
          console.log('OIDC configuration saved successfully');
          this.showSuccessAlert = true;
        } else {
          console.error(`Error saving OIDC configuration: ${response.statusText}`);
          this.showSuccessAlert = false;
        }
      } catch (error) {
        console.error(error);
        this.showSuccessAlert = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
